
export default (function () {
  const originalAddEventListener = EventTarget.prototype.addEventListener;

  EventTarget.prototype.addEventListener = function (type, listener, options) {
    // Проверяем, если событие относится к скроллу и опция `passive` не указана
    const isTouchEvent = ['touchstart', 'touchmove', 'wheel'].includes(type);

    if (isTouchEvent && typeof options === 'object' && options !== null) {
      options.passive = options.passive ?? true;
    } else if (isTouchEvent && options === undefined) {
      options = { passive: true };
    }
    // Вызов оригинального `addEventListener`
    originalAddEventListener.call(this, type, listener, options);
  };
})();
