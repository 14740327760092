// можно немного уменьшьить размер пакета, удалив ненужные js модули, но надо ковыряться и тестить

// import Carousel from 'bootstrap/js/dist/carousel';
// import Button from 'bootstrap/js/dist/button';
// import Dropdown from 'bootstrap/js/dist/dropdown';
// import Collapse from 'bootstrap/js/dist/collapse';

// import Modal from 'bootstrap/js/dist/modal';
// import Tooltip from 'bootstrap/js/dist/tooltip';
// import Popover from 'bootstrap/js/dist/popover';
// import Alert from 'bootstrap/js/dist/alert';
// import Toast from 'bootstrap/js/dist/toast';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';

import "./utils/jquery-hack";

import pnmPartsCardHack from "./utils/pnm-parts-card-hack";

import collapseFix from "./components/collapse";

// импортируем все компоненты
import * as bootstrap from "bootstrap";

// необходимо для работы меню
import menu from "./components/menu";

import accordion from "./components/accordion";

import removeImageLoaders from "./utils/remove-image-loaders";
// удаляем лоадеры
removeImageLoaders();

menu(bootstrap);

accordion();

pnmPartsCardHack(bootstrap);

// фикс проблем с collapse на pnmCards
collapseFix();


console.log("HOMOPARTS - запчасти для спецтехники!");
