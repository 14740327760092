const removeImageLoaders = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const imgs = document.querySelectorAll(".picture__link");

    imgs.forEach((img) => {
      img.addEventListener("load", () => {
        const background = img.querySelector(".picture__background");
        const picture = img.querySelector(".picture__image");
      });
      if (img.complete) {
        const background = img.querySelector(".picture__background");
        const picture = img.querySelector(".picture__image");
      }
    });

  });
};

export default removeImageLoaders;
