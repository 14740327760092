/**
 * Accordion component - фикс проблем с аккордионом из Bootstrap старой версии
 */
const accordion = () => {
  const accordions = document.querySelectorAll(".accordion")

  accordions.forEach((accordion) => {
    const cards = accordion.querySelectorAll(".accordion>.card")
    cards.forEach((card) => {
      const cardHeader = card.querySelector(".card-header>.btn-link")

      const cardBodyId = cardHeader.attributes["data-target"].value

      cardHeader.addEventListener("click", (event) => {
        card.querySelector(cardBodyId).classList.toggle("show")
        cardHeader.querySelectorAll(".fa").forEach((icon) => {
          icon.classList.toggle("show-trigger")
          icon.classList.toggle("hide-trigger")
        })
      })
    })
  })
}

export default accordion
