const pnmPartsCardHack = function (bootstrap) {
  // enable bootstrap tooltips
  const tooltipTriggerList = document.querySelectorAll('[data-toggle="html-tooltip"],[data-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) =>
      new bootstrap.Tooltip(tooltipTriggerEl, {
        html: true,
      })
  )

  //fix collapse description
  // const collapseTriggerList = document.querySelectorAll('[data-toggle="collapse"]')
  const collapseTrigger = document.querySelector('[aria-controls="collapseDesc"]')
  const collapseList = document.querySelector("#collapseDesc")
  if (!collapseTrigger || !collapseList) {
    return
  }
  collapseTrigger.addEventListener("click", (event) => {
    event.preventDefault()
    collapseList.classList.toggle("show")
    collapseTrigger.querySelectorAll(".fa").forEach((icon) => {
      icon.classList.toggle("show-trigger")
      icon.classList.toggle("hide-trigger")
    })
  })
}

export default pnmPartsCardHack
