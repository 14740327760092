/**
 * фикс проблем с collapse из Bootstrap старой версии
 */
const collapseFix = () => {
  const collapseBtn = document.querySelectorAll(
    '#collapseThree button[data-toggle="collapse"]'
  );
  collapseBtn.forEach((btn) => {
    btn.setAttribute("data-bs-toggle", "collapse");
    btn.setAttribute("data-bs-target", ".multi-collapse.no-price");
  });
};

export default collapseFix;
