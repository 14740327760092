const menu = (bootstrap) => {
  const menuWrapper = document.querySelector(".hp-menu-wrapper")
  const navbarCollapse = document.querySelector(".navbar-collapse")

  const hideNavbar = () => {
    navbarCollapse.style.overflow = "hidden"
    bootstrap.Collapse.getOrCreateInstance(navbarCollapse).hide()
    setTimeout(() => {
      document.body.style.overflow = "auto"
      menuWrapper.style.display = "none"
    }, 500)
  }

  navbarCollapse.addEventListener("hide.bs.collapse", hideNavbar)
  navbarCollapse.addEventListener("click", (event) => {
    if (event.target.classList.contains("navbar-collapse")) {
      hideNavbar()
    }
  })

  navbarCollapse.addEventListener("show.bs.collapse", (event) => {
    menuWrapper.style.display = "block"
    document.body.style.overflow = "hidden"
    navbarCollapse.style.overflow = "hidden"
    setTimeout(() => {
      navbarCollapse.style.overflow = "auto"
    }, 500)
  })
}

export default menu
